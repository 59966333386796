import { decode } from "html-entities";
import { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ThumbnailButton } from "../components";
import { Course } from "../courses/courses.types";
import "../i18n";
import { Loader } from "../loaders/loader.component";
import { ScormCourseContent } from "../scorm-support/scorm-course-content";
import { getCourseDetails } from "./course-details.service";
import { CourseProgressState } from "./course-progress.enum";
import { createCourseProgress, getCourseProgress, updateCourseProgress } from "./course-progress.service";
import { CourseProgress } from "./course-progress.types";
import { PageLayout } from "../components/Pages/PageLayout";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCertificateByCourseId } from "../certificate/certificate.service";
import { Modal } from "../components/Modal/Modal";


type CourseDetailsParams = {
  id: string;
}

const lastExamTakenAtLeast24HoursAgo = (dateStr: string) => {
  const date = new Date(dateStr);
  return Date.now() - date.getTime() > 24 * 60 * 60 * 1000;
};

export const CourseDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<CourseDetailsParams>();
  const [course, setCourse] = useState<Course>({} as Course);
  const [userCourseProgress, setUserCourseProgress] = useState<CourseProgress>({} as CourseProgress);
  const [buttonProps, setButtonProps] = useState<{ label: string, action?: MouseEventHandler<HTMLButtonElement> }>({ label: "" });
  const [isCertificateAvailable, setCertificateAvailable] = useState(false);
  const [showExamUnavailableModal, setShowExamUnavailableModal] = useState(false);
  const [loading, setLoading] = useState(true);
  let emptyWindow: WindowProxy | null;
  const currentVideoName = "cmi.suspend_data";
  const currentVideoStatus = "cmi.core.lesson_status";

  const queryClient = useQueryClient();

  useEffect(() => {
    setCertificateAvailable(userCourseProgress.state === CourseProgressState.COMPLETED);
  }, [userCourseProgress]);


  const { data: certificate } = useQuery({
    queryKey: ["course_certificate", course?.id],
    queryFn: () => getCertificateByCourseId(course?.id),
    enabled: !!course?.id,
  });

  const handleUpdateCourseProgress = async (id: string, body: CourseProgress) => {
    queryClient.invalidateQueries({ queryKey: ["recentProgressCount"] });
    await updateCourseProgress(id, body);
    setUserCourseProgress({ position: body.position, state: body.state });
  };

  const executeAppropriateAction = (courseUrl: string) => {
    emptyWindow = window.open("", "_blank", "fullscreen=yes|rel=opener") as WindowProxy;
    emptyWindow.document.write(ScormCourseContent(courseUrl));
    emptyWindow.document.title = "Relias Learning GmbH";
    emptyWindow.onload = () => {
      emptyWindow?.window.postMessage({ ...localStorage });
    };
  };

  const MessageListener = async (event: MessageEvent) => {
    if (event.data.logout === "logout") {
      navigate("/logout");
      if (emptyWindow !== null) {
        emptyWindow.close();
      }
    }
    if (event.origin === window.location.origin) {
      if (event.data[currentVideoStatus] === "passed" || event.data[currentVideoStatus] === "completed") {
        window.removeEventListener("message", MessageListener, false);// removing listener,
        // TODO: will rebind it when user starts the course again, for re-viewing the course again
        console.log("Course Completed,detached listener for message event.");
        const body: CourseProgress = {
          position: `${event.data[currentVideoName]}`,
          state: CourseProgressState.FINISHEDWATCHING
        };
        await handleUpdateCourseProgress(id as string, {
          ...body
        });
      } else if (event.data[currentVideoName]) {
        const body: CourseProgress = {
          position: `${event.data[currentVideoName]}`,
          state: CourseProgressState.INPROGRESS
        };
        await handleUpdateCourseProgress(id as string, {
          ...body
        });
      }
      if (event.data) {
        Object.keys(event.data).map((entry) => {
          if (entry === currentVideoName || entry === currentVideoStatus) {
            localStorage.setItem(entry, event.data[entry]);
          }
        });
      }
    }
  };

  const executeSetButtonText = () => {
    switch (userCourseProgress.state) {
      case CourseProgressState.NOTSTARTED:
        return setButtonProps({ label: t("courseDetail.start"), action: () => executeAppropriateAction(course.scormUrl) });
      case CourseProgressState.INPROGRESS:
        return setButtonProps({ label: t("courseDetail.resume"), action: () => executeAppropriateAction(course.scormUrl) });
      case CourseProgressState.FINISHEDWATCHING:
        return setButtonProps({
          label: t("courseDetail.startExam"), action: () => {
            const canTakeExam = !certificate || lastExamTakenAtLeast24HoursAgo(certificate.completedOn);
            if (canTakeExam) {
              navigate(`/exam/${id}`);
            } else {
              setShowExamUnavailableModal(true);
            }
          }
        });
      case CourseProgressState.COMPLETED:
        return setButtonProps({ label: t("courseDetail.repeat"), action: () => executeAppropriateAction(course.scormUrl) });
      default:
        return setButtonProps({ label: "" });
    }
  };

  useEffect(() => {
    async function fetchCourseDetails() {
      try {
        setLoading(true);
        const course = await getCourseDetails(id as string);
        const userCourseProgress = await getCourseProgress(id as string);
        if (!userCourseProgress) {
          await createCourseProgress(id as string);
          setUserCourseProgress({ position: "0", state: CourseProgressState.NOTSTARTED });
        } else {
          localStorage.setItem(currentVideoName, userCourseProgress.position);
          setUserCourseProgress({ position: userCourseProgress.position, state: userCourseProgress.state });
        }
        setCourse(course);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setLoading(false);
      }
    }
    fetchCourseDetails();
    window.addEventListener("message", MessageListener, false);
    return () => window.removeEventListener("message", MessageListener, false);
  }, [id]);

  useEffect(() => {
    if (userCourseProgress?.state) {
      executeSetButtonText();
    }
  }, [userCourseProgress]);

  return (loading === true ? <Loader></Loader>
    : <PageLayout>
      <div>
        <div className="flex flex-row items-stretch gap-4">
          <Button
            label={`❮ ${t("common.goBack")}`}
            action={() => navigate(-1)}
          />
          {isCertificateAvailable && <Button
            className="flex flex-row items-center gap-2"
            action={() => navigate(`/certificate/?course=${id}`)}
            kind="secondary">
            <img src="/certificate.svg" alt="" className="w-4 h-4" />
            {t("certificates.goToCertificate")}
          </Button>}
        </div>
        <h1 className="my-8 font-bold text-3xl">{course.title}</h1>
        <div className="relative flex flex-col items-center">
          <img className="w-full max-h-80 object-cover object-center" src={course.thumbnailUrl} alt={course.title} />
          <div className="flex bg-white bg-opacity-30 absolute w-full h-full items-center justify-center">
            <div className="flex flex-col gap-4 items-stretch w-30">
              <ThumbnailButton
                label={buttonProps.label}
                action={buttonProps.action!}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-8 mt-8">
          {course.description ? (
            <div>
              <h2 className="mt-6 font-semibold">{t("courseDetail.learnerCourseDescription")}</h2>
              <div dangerouslySetInnerHTML={{ __html: decode(course.description).trim() }} className="text-sm leading-relaxed" />
            </div>
          ) : null}
          {course.goal ? (
            <div>
              <h3 className="mt-6 font-semibold">{t("courseDetail.goal")}</h3>
              <div dangerouslySetInnerHTML={{ __html: decode(course.goal).trim() }} className="text-sm leading-relaxed" />
            </div>
          ) : null}
          <div>
            <h3 className="mt-4 font-semibold">{t("courseDetail.rbp")}</h3>
            <p className="text-sm">{course.rbp || t("courseDetail.none")}</p>
          </div>
          {course.contributor ? (
            <div>
              <h3 className="mt-4 font-semibold">{t("courseDetail.author")}</h3>
              <p className="sm:mb-4 text-sm">{decode(course.contributor)}</p>
            </div>
          ) : null}
        </div>
      </div>
      {showExamUnavailableModal && <Modal
        className="w-[min(80vw,480px)]"
        message={t`courseDetail.exam24HourThrottleMsg`}
        buttons={["ok"]}
        onAction={() => setShowExamUnavailableModal(false)}
        hasXCloseButton
      />}
    </PageLayout>
  );
};
