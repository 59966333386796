import { FC } from "react";
import { PageTitle } from "../components/Pages/PageTitle";
import { CourseSearch } from "./course-search";

export type CoursesHeaderProps = {
  inputValue: string;
  setInputValue: (query: string) => void;
  searchTerm: string;
  onSearch: () => void;
  title: JSX.Element | string;
}

export const CoursesHeader: FC<CoursesHeaderProps> = (props) => {
  const { inputValue, setInputValue, onSearch, title } = props;
  return (
    <header className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-y-6">
      <div className="flex flex-row items-center gap-4">
        <PageTitle title={title} />
      </div>
      <CourseSearch inputValue={inputValue} setInputValue={setInputValue} onSearch={onSearch} />
    </header>
  );
};
