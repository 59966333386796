import { FC } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../utils/classnames";
import { Button } from "../buttons/Button";


const buttonKinds = {
  "ok": "common.ok",
  "cancel": "common.cancel",
  "close": "common.close",
} as const;

export type ModalButtonKind = keyof typeof buttonKinds;

export type ModalContent = JSX.Element | JSX.Element[] | string;

export type ModalProps = {
  className?: string;
  message: ModalContent
  buttons: ModalButtonKind[]
  hasXCloseButton?: boolean
  onAction: (kind: ModalButtonKind) => void
};

type ModalButtonProps = {
  primary: boolean;
  kind: ModalButtonKind;
  onAction: (kind: ModalButtonKind) => void;
};

const ModalButton: FC<ModalButtonProps> = ({ primary, kind, onAction }) => {
  const { t } = useTranslation();
  return <Button
    kind={primary ? "primary" : "secondary"}
    action={() => onAction(kind)}
  >
    {t(buttonKinds[kind])}
  </Button>;
};

export const Modal: FC<ModalProps> = ({ className, message, buttons, onAction, hasXCloseButton }) => {
  const { t } = useTranslation();
  const closeLabel = t(buttonKinds.close);
  return <div
    className="fixed inset-0 bg-black bg-opacity-50 z-[10_000] flex items-center justify-center mt-[80px]"
  >
    <div className={classNames(
      "bg-white rounded-lg relative overflow-clip",
      className
    )}>
      {hasXCloseButton && <button
        className="absolute -top-2 -right-2 p-4 opacity-50 hover:opacity-75 hover:scale-150 transition-all duration-200"
        onClick={() => onAction("close")}>
        <img src="/cross.svg" alt={closeLabel} title={closeLabel} />
      </button>}
      <div className="flex flex-col gap-4 items-center text-center justify-start">
        <div className={classNames("min-h-14 flex text-left items-center justify-start px-8 pt-8 pb-4")}>{message}</div>
        <ul className="flex flex-row gap-4 w-full bg-zinc-300 px-8 py-3">
          {buttons.map((kind, i) => <li key={kind}>
            <ModalButton primary={!i} kind={kind} onAction={onAction} />
          </li>)}
        </ul>
      </div>
    </div>
  </div>;
};
