import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { CoursesHeader } from "./courses-header";
import { CourseFeedResponse } from "./courses.types";
import { useAppToken } from "../app/hooks/useAppToken";
import { PageLayout } from "../components/Pages/PageLayout";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getCoursesAssignedToCustomerInContext } from "./courses.service";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";
import { CourseCard } from "../course-card/course-card.component";
import { useInView } from "react-intersection-observer";
import { NO_ACCESS_TOKEN } from "../@types/errors.types";


const PAGE_SIZE = 12;

export type CourseFeedPageProps = {
  page: CourseFeedResponse;
  pageNumber: number;
};

const PageDivider: FC<{ label: number | string }> = ({ label }) =>
  <div className="
    sm:col-span-2 lg:col-span-3 xl:col-span-4
    flex flex-row items-center justify-center my-4
  ">
    <div className="flex-grow border-t border-t-gray-300 relative">
      <button
        className="absolute bg-[#FAF9F6] -top-3 border border-transparent border-r-gray-300 rounded-md pr-2 hover:border-gray-300"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >&uarr; Zum Anfang</button>
    </div>
    <div className="px-4 text-center text-gray-400 font-semibold">
      {label}
    </div>
    <div className="flex-grow border-t border-t-gray-300" />
  </div>;


const CourseFeedPage: FC<CourseFeedPageProps> = ({ page, pageNumber }) =>
  <Fragment key={`page-${page.from}`}>
    {pageNumber > 1 ? <PageDivider label={pageNumber} /> : null}
    {page.courses.map((data) => <CourseCard key={data.course.id} course={data} />
    )}
  </Fragment>;


export const Courses: React.FC = () => {
  const { t } = useTranslation();
  const token = useAppToken();
  const [searchInput, setSearchInput] = useState("");
  const [queryKey, setQueryKey] = useState("");
  const [userChangedSearch, setUserChangedSearch] = useState(false);

  const prevIntersection = useInView();
  const nextIntersection = useInView();

  const queryClient = useQueryClient();

  const handleSearch = () => {
    setUserChangedSearch(true);
    setQueryKey(searchInput);
  };

  const { fetchNextPage, hasNextPage, data, isError, isLoading, } =
    useInfiniteQuery({
      staleTime: 1000 * 60 * 30,
      initialPageParam: 0,
      queryKey: ["courses", queryKey],
      queryFn: ({ pageParam: skip, queryKey }) => {
        const [_, searchQuery] = queryKey;
        return getCoursesAssignedToCustomerInContext({
          searchQuery,
          skip,
          take: PAGE_SIZE,
        });
      },
      getNextPageParam: (currentLastPage) =>
        currentLastPage.to < currentLastPage.total
          ? Math.min(currentLastPage.total, currentLastPage.to)
          : undefined,
      getPreviousPageParam: (currentFirstPage) =>
        currentFirstPage.from > 0
          ? Math.max(0, currentFirstPage.from - PAGE_SIZE)
          : undefined,
    });

  useErrorRedirect(!token, NO_ACCESS_TOKEN);
  useErrorRedirect(isError);

  const totalCourseCount = data?.pages?.[0].total ?? 0;
  const pageCount = data?.pages?.length ?? 0;

  useEffect(() => {
    if (!userChangedSearch || queryKey !== "") return;
    queryClient.removeQueries({
      predicate: (query) =>
        Array.isArray(query.queryKey)
        && query.queryKey[0] === "courses"
        && query.queryKey[1] !== ""
    });
  }, [queryKey]);

  useEffect(() => {
    // populate search input with the last query key
    if (userChangedSearch || queryKey) return;
    console.log(queryClient.getQueryCache().getAll());
    const matchingQueries = queryClient
      .getQueryCache()
      .getAll()
      .filter((query) =>
        Array.isArray(query.queryKey)
        && query.queryKey[0] === "courses");
    const lastMatchingQuery = matchingQueries.at(-1);
    console.log(lastMatchingQuery);
    setSearchInput((lastMatchingQuery?.queryKey?.[1] ?? "") as string);
    setQueryKey((lastMatchingQuery?.queryKey?.[1] ?? "") as string);
  }, [queryClient, queryKey, userChangedSearch]);

  useEffect(() => {
    if (nextIntersection.inView) fetchNextPage();
  }, [nextIntersection.inView]);

  const title: JSX.Element | string =
    queryKey && totalCourseCount ?
      <Trans i18nKey="courses.titleNCoursesFound" values={{ count: totalCourseCount }} />
      : queryKey ? t`courses.titleNoCoursesFound`
        : t`courses.titleAllCourses`;

  return <PageLayout>
    <CoursesHeader
      inputValue={searchInput}
      setInputValue={(value) => {
        setUserChangedSearch(true);
        setSearchInput(value);
      }}
      searchTerm={queryKey}
      onSearch={handleSearch}
      title={title}
    />
    <div ref={prevIntersection.ref}>&nbsp;</div>
    {!isLoading && !totalCourseCount && <p className="text-2xl">{t`courses.bodyNoCoursesFound`}</p>}
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6">
      {data?.pages && data.pages.map((page) =>
        <CourseFeedPage key={page.from} page={page} pageNumber={(page.from / PAGE_SIZE) + 1} />
      )}
    </div>
    {hasNextPage ? <div className="mt-12" ref={nextIntersection.ref}>&nbsp;</div>
      : pageCount > 1 ? <PageDivider label={t`common.end`} />
        : null
    }
  </PageLayout>;
};

