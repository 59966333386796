import { FC, FormEvent, useEffect, useState } from "react";
import { Button } from "../components";
import { useTranslation } from "react-i18next";

export type CourseSearchProps = {
  onSearch: () => void;
  inputValue: string;
  setInputValue: (inputValue: string) => void;
};

export const CourseSearch: FC<CourseSearchProps> = (props) => {
  const { t } = useTranslation();
  const { onSearch, inputValue, setInputValue } = props;
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch();
  };

  const handleReset = () => {
    setInputValue("");
  };

  return (
    <form className="flex flex-row items-stretch w-full md:w-auto" onSubmit={handleSubmit}>
      <div className="relative w-full lg:w-auto">
        <label htmlFor="search" className="sr-only">{t`courses.searchSrVerbiage`}</label>
        <input
          autoComplete="on"
          id="search"
          type="text"
          placeholder={t`courses.searchPlaceholder`}
          className="w-full lg:w-[32ch] px-1 pr-6 border border-gray-700 rounded-l-md h-full"
          value={inputValue}
          onKeyUp={(e) => e.key === "Escape" && handleReset()}
          onChange={(e) => setInputValue(e.target.value)} />
        {inputValue && <button type="button" onClick={handleReset} className="absolute right-2 top-1 bottom-1">
          <img src="/cross.svg" alt="" className="h-3" />
        </button>}
      </div>
      <Button type="submit" className="flex flex-row gap-2 my-0 rounded-l-none">
        <img src="/search.svg" alt="" className="" />
        <span className="sr-only">{t`courses.searchButton`}</span>
      </Button>
    </form>
  );
};
