import { AppRouter } from "./app.router";
import { NavItem } from "./components/Navigation/NavItem";
import { Navigation } from "./components/Navigation/Navigation";
import { useTranslation } from "react-i18next";
import { getRecentProgressCount } from "./course-details/course-progress.service";
import { classNames } from "./utils/classnames";
import { useQuery } from "@tanstack/react-query";

const Header = () => {
  const { data: recentCount } = useQuery({
    queryKey: ["recentProgressCount"],
    queryFn: getRecentProgressCount,
  });
  const { t } = useTranslation(undefined, {
    keyPrefix: "navigation",
  });

  const navigateToHomePage = () => AppRouter.navigate(`/`);
  return <>
    <header
      className={classNames(
        "fixed top-0 left-0 right-0 z-50",
        "h-[80px] py-5 bg-gray-700 text-white text-center flex flex-row items-center gap-4 px-8"
      )}
    >
      <a onClick={navigateToHomePage}>
        <img src="/relias.svg" alt="Relias" className="max-h-10" />
      </a>
      <p className="font-light text-slate-400 md:mr-8">LTI 1.1</p>
      <Navigation>
        <NavItem label={t`library`} href="/" />
        <NavItem
          label={t`recentCourses`}
          href="/recent"
          badge={!recentCount ? undefined : Math.min(5, recentCount)}
        />
        <NavItem label={t`certificates`} href="/certificates" />
      </Navigation>
    </header>
    <div className="min-h-[80px]">&nbsp;</div> {/* account for fixed header */}
  </>;
};

export { Header };
