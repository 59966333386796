import { executeRestAPICall } from "../services/generic.service";
import { CourseFeedResponse } from "./courses.types";

type Params = { searchQuery?: string, skip?: number, take?: number }

const ASSIGNED_COURSES_API = `${process.env.REACT_APP_LTI_BACKEND_API}/api/courses-assigned-to-customer/feed`;

const getCoursesAssignedToCustomerInContext = async (params: Params) => {
  const { searchQuery, skip = 0, take = 20 } = params;
  return await executeRestAPICall({
    method: "GET",
    url: `${ASSIGNED_COURSES_API}?q=${searchQuery ?? ""}&skip=${skip}&take=${take}`,
  }) as CourseFeedResponse;
};

export { getCoursesAssignedToCustomerInContext };
